import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import galleryImg1 from "../images/gallery/gallery-img-10.jpg"
import galleryImg2 from "../images/gallery/gallery-img-2.jpg"
import galleryImg3 from "../images/gallery/gallery-img-3.jpg"
import galleryImg5 from "../images/gallery/gallery-img-5.jpg"
import galleryImg6 from "../images/gallery/gallery-img-6.jpg"
import galleryImg7 from "../images/gallery/gallery-img-7.jpg"
import galleryImg8 from "../images/gallery/gallery-img-8.jpg"
import galleryImg9 from "../images/gallery/gallery-img-9.jpg"
import galleryImg11 from "../images/gallery/gallery-img-11.jpg"
import galleryImg13 from "../images/gallery/gallery-img-13.jpg"
import galleryImg15 from "../images/gallery/gallery-img-15.jpg"
import galleryImg16 from "../images/gallery/gallery-img-16.jpg"
import galleryImg17 from "../images/gallery/gallery-img-17.jpg"
import galleryImg18 from "../images/gallery/gallery-img-18.jpg"
import galleryImg19 from "../images/gallery/gallery-img-19.jpg"
import galleryImg20 from "../images/gallery/gallery-img-20.jpg"
import galleryImg21 from "../images/gallery/gallery-img-21.jpg"
import galleryImg22 from "../images/gallery/gallery-img-22.jpg"
import galleryImg23 from "../images/gallery/gallery-img-23.jpg"
import galleryImg24 from "../images/gallery/gallery-img-24.jpg"
import galleryImg25 from "../images/gallery/gallery-img-25.jpg"
import galleryImg26 from "../images/gallery/gallery-img-26.jpg"
import galleryImg27 from "../images/gallery/gallery-img-27.jpg"
import galleryImg28 from "../images/gallery/gallery-img-28.jpg"
import galleryImg29 from "../images/gallery/gallery-img-29.jpg"
import galleryImg30 from "../images/gallery/gallery-img-30.jpg"
import galleryImg31 from "../images/gallery/gallery-img-31.jpg"
import galleryImg32 from "../images/gallery/gallery-img-32.jpg"
import galleryImg33 from "../images/gallery/gallery-img-33.jpg"

const Gallery = () => (
    <Layout>
        <SEO title="Our Story" />
        <div className="container gallery px-5 mx-auto xl:px-20">
            <h1 className="text-center our-story__title font-title mt-20 mb-10">Gallery</h1>
            <div className="flex flex-wrap mt-10">
                <div className="w-full md:w-3/12">
                    <img src={galleryImg6} alt="" className="p-2 w-full" />
                    <img src={galleryImg22} alt="" className="p-2 w-full" />
                </div>
                <div className="w-full md:w-6/12">
                    <img src={galleryImg3} alt="" className="p-2 w-full" />
                </div>
                <div className="w-full md:w-3/12">
                    <img src={galleryImg23} alt="" className="p-2 w-full" />
                    <img src={galleryImg21} alt="" className="p-2 w-full" />
                </div>
            </div>
            <div className="flex flex-wrap">
                <div className="w-full md:w-3/12">
                    <img src={galleryImg7} alt="" className="p-2 w-full" />
                </div>
                <div className="w-full md:w-6/12 flex justify-center flex-wrap">
                    <img src={galleryImg8} alt="" className="p-2 w-1/2" />
                    <img src={galleryImg16} alt="" className="p-2 w-1/2" />
                </div>
                <div className="w-full md:w-3/12">
                    <img src={galleryImg5} alt="" className="p-2 w-full" />
                </div>
            </div>
            <div className="flex flex-wrap">
                <div className="w-full md:w-6/12">
                    <img src={galleryImg11} alt="" className="p-2 w-full" />
                </div>
                <div className="w-full md:w-3/12">
                    <img src={galleryImg2} alt="" className="p-2 w-full" />
                    <img src={galleryImg24} alt="" className="p-2 w-full" />
                </div>
                <div className="w-full md:w-3/12">
                    <img src={galleryImg1} alt="" className="p-2 w-full" />
                    <img src={galleryImg15} alt="" className="p-2 w-full" />
                </div>
            </div>
            <div className="flex flex-wrap">
                <div className="w-full md:w-3/12">
                    <img src={galleryImg9} alt="" className="p-2 w-full" />
                </div>
                <div className="w-full md:w-6/12 flex justify-center flex-wrap">
                    <img src={galleryImg26} alt="" className="p-2 w-1/2" />
                    <img src={galleryImg27} alt="" className="p-2 w-1/2" />
                </div>
                <div className="w-full md:w-3/12">
                    <img src={galleryImg25} alt="" className="p-2 w-full" />
                </div>
            </div>
            <div className="flex flex-wrap">
                <div className="w-full md:w-6/12">
                    <a href="https://youtu.be/dyoHmIxXOY8" target="_blank" className="cursor-pointer"><img src={galleryImg33} alt="" className="p-2 w-full" /></a>
                </div>
                <div className="w-full md:w-6/12">
                    <a href="https://www.facebook.com/flor.aviso/videos/2097635310273212/UzpfSTc3NTI0ODc3MjgzNDA4Nzo4MDExNTI5MDY5MTAzNDA/" target="_blank" className="cursor-pointer"><img src={galleryImg20} alt="" className="p-2 w-full" /></a>
                </div>
            </div>
            <div className="flex flex-wrap">
                <div className="w-full md:w-3/12">
                    <img src={galleryImg13} alt="" className="p-2 w-full" />
                </div>
                <div className="w-full md:w-6/12 flex justify-center flex-wrap">
                    <img src={galleryImg17} alt="" className="p-2 w-1/2" />
                    <img src={galleryImg19} alt="" className="p-2 w-1/2" />
                </div>
                <div className="w-full md:w-3/12">
                    <img src={galleryImg31} alt="" className="p-2 w-full" />
                </div>
            </div>
            <div className="flex flex-wrap">
                <div className="w-full md:w-3/12">
                    <img src={galleryImg28} alt="" className="p-2 w-full" />
                    <img src={galleryImg29} alt="" className="p-2 w-full" />
                </div>
                <div className="w-full md:w-6/12">
                    <img src={galleryImg32} alt="" className="p-2 w-full" />
                </div>
                <div className="w-full md:w-3/12">
                    <img src={galleryImg18} alt="" className="p-2 w-full" />
                    <img src={galleryImg30} alt="" className="p-2 w-full" />
                </div>
            </div>
        </div>
    </Layout>
)

export default Gallery